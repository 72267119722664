import { doc, collection, getDocs } from "firebase/firestore";

import { db } from './firebase';

import React, { useState } from 'react';

const doc_to_arr = (doc) => {
  let ret = [];
  ret.push(doc.id);
  ret.push(doc.data().lang);

  let data = doc.data().score;

  ret = [...ret, ...data];

  return ret;
}

function App() {


  let [scores, update_scores] = useState({ scores: [] });


  let on_reload = async () => {
    try {


      const docSnap = await getDocs(collection(db, "scores"));

      let s = [];

      docSnap.forEach((doc) => {
        s.push(doc_to_arr(doc));
      });
      update_scores({ scores: s });

    } catch (e) {
      console.error("Error adding document: ", e);
    }

  }



  return (
    <div className="App">
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 mb-10" onClick={on_reload}>Reload</button>

      <table className="border-collapse border border-slate-400">
        <thead>
          <tr>
            <th className="border border-slate-300">ID</th>
            <th className="border border-slate-300">LANG</th>
            {
              [...Array(60).keys()].map((n) => {

                let k = n.toString();
                if (n < 10) {
                  k = "0" + k;
                }
                return (
                  <th className="border border-slate-300" key={n}>{k}</th>)
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            scores.scores.map((score, i) => {
              return (<tr key={i}>
                {
                  score.map((s, j) => {
                    return (<td className="border border-slate-300" key={j}>{s}</td>)
                  })
                }
              </tr>)
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default App;
